@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* mobile view */
@media screen and (max-width: 575px) {
  .mbl-view {
    display: none;
  }
  .company-name {
    display: none;
  }
  .mbl-name {
    display: none;
  }
}

/* tab-mode */
@media screen and (min-width: 575px) and (max-width: 991px) {
  .tab-view {
    display: none;
  }
}

.active {
  color: white;
  font-weight: bold;
  text-shadow: 4px 2px 2px 2px;
  border: 2px solid;
  background-color: green;
  padding: 12px;
  border-radius: 6px;
  width: 200px;
  text-align: center;
}
